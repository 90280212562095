// margin bottom
.mb-10
	margin-bottom: 10px

.mb-20
	margin-bottom: 20px

.mb-30
	margin-bottom: 30px

.mb-40
	margin-bottom: 40px

.mb-50
	margin-bottom: 50px

.mb-60
	margin-bottom: 60px

.mb-70
	margin-bottom: 70px

.mb-80
	margin-bottom: 80px

.mb-90
	margin-bottom: 90px

.mb-100
	margin-bottom: 100px

// margin top
.mt-10
	margin-top: 10px

.mt-20
	margin-top: 20px

.mt-30
	margin-top: 30px

.mt-40
	margin-top: 40px

.mt-50
	margin-top: 50px

.mt-60
	margin-top: 60px

.mt-70
	margin-top: 70px

.mt-80
	margin-top: 80px

.mt-90
	margin-top: 90px

.mt-100
	margin-top: 100px

// padding top
.pt-10
	padding-top: 10px
